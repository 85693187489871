import { Icon } from "@blueprintjs/core"

const Footer = () => {
  return (
    <footer className="footer footer-center p-10 bg-primary text-primary-content">
      <aside>
        <span className='text-4xl'><Icon icon='data-connection' /></span>
        <p className="font-bold">
          resgrok.com
        </p>
        <p>Copyright © {new Date().getFullYear()} - All right reserved.</p>
        <p className="text-xs text-gray-400">
          Contains HM Land Registry data © Crown copyright and database right 2021. <br />This data is licensed under the Open Government Licence v3.0.
        </p>
      </aside>
    </footer>
  )
}

export {
  Footer
}