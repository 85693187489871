import './App.css';
import { Alignment, Button, Icon, Navbar } from '@blueprintjs/core';
import { Dash } from './component/dash';
import { Footer } from './component/footer';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import { ClerkProvider, RedirectToSignIn, SignInButton, SignOutButton, SignUpButton, SignedIn, SignedOut, useAuth, useUser } from '@clerk/clerk-react';
import { LandingPage } from './pages/landing_page';

const Page = ({ children, showNav = true }: any) => {
  const { user } = useUser()
  const navigate = useNavigate()

  return (
    <div className='flex flex-col min-h-screen'>
      <main className='flex-1'>
        {
          showNav && (
            <Navbar>
              <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading>
                  <span onClick={() => navigate('/')} className='hover:cursor-pointer font-bold'><Icon icon='data-connection' /> resgrok</span>
                </Navbar.Heading>
                <Navbar.Divider />
                
                <SignedIn>
                  <Button onClick={() => navigate('/dash')} className='bp5-minimal' icon='dashboard' text='Dashboard' />
                </SignedIn>
                <SignedOut>
                  <Button onClick={() => navigate('/')} className='bp5-minimal' text='Home' />
                </SignedOut>
                
                <SignedOut>
                  <Button className='bp5-minimal' text='Pricing' />
                </SignedOut>
              </Navbar.Group>

              <Navbar.Group align={Alignment.RIGHT}>
                <SignedIn>
                  <div className='flex flex-row gap-2 items-center'>
                    {user && <p>Welcome <span className='capitalize font-bold'>{user.username || user.primaryEmailAddress?.emailAddress.split('@')[0] || ''}</span>!</p>}
                    <SignOutButton>
                      <Button className='bp5-intent-danger' small minimal>
                        Sign Out
                      </Button>
                    </SignOutButton>
                  </div>
                </SignedIn>
                <SignedOut>
                  <SignInButton>
                    <Button className='bp5-minimal' text='Log in' />
                  </SignInButton>

                  {/* <SignUpButton>
                    <Button className='bp5-intent-primary' outlined text='Sign up' />
                  </SignUpButton> */}

                  <Navbar.Divider />
                  <Button onClick={() => {
                    window.open('https://cal.com/felixangell/demo', '_blank')?.focus()
                  }} className='bp5-intent-primary' icon='presentation' text='Request demo' />
                </SignedOut>
              </Navbar.Group>
            </Navbar>
          )
        }

        <div className='flex flex-row gap-2 w-full justify-around'>
          {children}
        </div>
      </main>

      <div className='flex-shrink-0'>
        <Footer />
      </div>
    </div >
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page showNav>
      <LandingPage />
    </Page>
  },
  {
    path: "/dash",
    element: <Page>
      <SignedIn>
        <Dash />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </Page>
  }
])

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;


function App() {
  return (
    <div className="App">
      <ClerkProvider publishableKey={clerkPubKey}>
        <RouterProvider router={router} />
      </ClerkProvider>
    </div>
  );
}

export default App;
