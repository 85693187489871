import { Button, Card, Elevation, Icon, IconName, MaybeElement } from "@blueprintjs/core"
import { SignInButton, SignUpButton, SignedIn, SignedOut } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom"

const SellingPoint = ({ icon, headline, children }: { icon: IconName | MaybeElement } | any) => {
  return (
    <Card className="lg:w-1/2 w-1/1 rounded-2xl shadow-xl flex flex-row">
      <div className="py-4 items-center flex flex-col lg:flex-row">
        <span className="m-8 my-2 md:my-8">
          <Icon className="bp5-intent-primary" size={64} icon={icon} />
        </span>

        <div className="text-left">
          <h3 className="text-xl font-bold my-4">{headline}</h3>
          <div className="leading-relaxed text-lg">
            {children}
          </div>
        </div>
      </div>
    </Card>
  )
}

const LandingPage = () => {
  const navigate = useNavigate()

  return (
    <div>
      <section className="m-4 flex flex-col justify-around">
        <div
          className="flex flex-col items-center justify-around min-h-64">
          <div className="md:w-3/6 w-1/1 flex flex-col items-center my-12">
            <h1 className="text-6xl font-bold my-4">Empower Your Property Analysis with Smart, AI-Assisted, Data-Driven Insights</h1>

            <div className="">
              <p className="text-2xl leading-relaxed">
                Transform land analysis with our <span className="font-bold">AI-driven platform</span> leveraging <span className="font-bold">fast data analysis</span> for <span className="font-bold">unparalleled insights</span>.
              </p>
            </div>

            <div className="flex flex-col gap-2 my-8">
              <SignedIn>
                <Button onClick={() => navigate('/dash')} className="bp5-intent-primary" large>Go to dashboard</Button>
              </SignedIn>

              <SignedOut>
                {/* <SignUpButton>
                  <Button className="bp5-intent-primary" large>Sign up with email</Button>
                </SignUpButton> */}

                <div>
                  <SignInButton>
                    <Button className="bp5-intent-primary" minimal small>Log in</Button>
                  </SignInButton>
                </div>
              </SignedOut>
            </div>
          </div>

        </div>

        <div className="flex flex-row justify-around">
          <div className="flex flex-row w-1/1 md:w-3/6">
            <img className="shadow-xl rounded-2xl" src='https://i.imgur.com/UqUwgxN.png' />
          </div>
        </div>

        <div className="flex flex-row justify-around w-full">
          <div className="md:w-3/4 w-1/1 flex flex-col items-center my-12">
            <div className="flex md:flex-col flex-col justify-around my-8 gap-8">
              <div className="flex md:flex-row flex-col gap-16">
                <SellingPoint headline='Empowering property professionals to make faster, more informed decisions' icon='chart'>
                  <p>
                      Quicker decision-making translates into expedited project timelines, allowing you to capitalize on market opportunities ahead of competitors.
                  </p>
                </SellingPoint>
                <SellingPoint headline='Save Money with Smarter Property Analysis' icon='bank-account'>
                  <p>
                    By streamlining the process of data analysis and site sourcing, we aid businesses in reducing operational expenses by saving on resources typically spent on extensive research and analysis.
                  </p>
                </SellingPoint>
              </div>

              <div className="flex md:flex-row flex-col gap-16">
                <SellingPoint headline='Unified Data for Enhanced Property Insights' icon='data-lineage'>
                  <p>
                    Our platform merges information into a single, accessible space. This integration not only simplifies data handling but also provides a holistic view of potential projects, ensuring you have all the insights you need at your fingertips.
                  </p>
                </SellingPoint>
                <SellingPoint headline='Round-the-Clock Support' icon='time'>
                  <p>
                    We offer continuous, round-the-clock support to ensure you have the help you need, whenever you need it. Our dedicated team of experts is available to assist with any queries or challenges you may encounter, ensuring minimal downtime and maximum productivity for your business.
                  </p>
                </SellingPoint>
              </div>
            </div>
          </div>
        </div>

      </section>

    </div>
  )
}

export {
  LandingPage
}