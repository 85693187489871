// @ts-ignore
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { useEffect, useState } from 'react';

// lat: 24.886, lng: -70.268
const SimpleMap = ({ lat = 24.886, lng = -70.268, zoom }: any) => {
  const [loaded, setLoaded] = useState(false)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCGtmDSmQQwK3TRUb5zu-SGCDMrVIzc2zE"
  })
  const [map, setMap] = React.useState<any>(null)

  const onLoad = React.useCallback(function callback(map: any) {

    setMap(map)

  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])



  useEffect(() => {
    if (map && map.data) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: lat,
        lng: lng,
      });
      // map.fitBounds(bounds);
      const outerCoords = [
        { lat: -32.364, lng: 153.207 }, // north west
        { lat: -35.364, lng: 153.207 }, // south west
        { lat: -35.364, lng: 158.207 }, // south east
        { lat: -32.364, lng: 158.207 }, // north east
      ];

      // Define the LatLng coordinates for an inner path.
      const innerCoords1 = [
        { lat: -33.364, lng: 154.207 },
        { lat: -34.364, lng: 154.207 },
        { lat: -34.364, lng: 155.207 },
        { lat: -33.364, lng: 155.207 },
      ];

      // Define the LatLng coordinates for another inner path.
      const innerCoords2 = [
        { lat: -33.364, lng: 156.207 },
        { lat: -34.364, lng: 156.207 },
        { lat: -34.364, lng: 157.207 },
        { lat: -33.364, lng: 157.207 },
      ];

      console.log('trying to load camden geojson')

      // https://developers.google.com/maps/documentation/javascript/datalayer
      
      // map.data.add({
      //   geometry: new google.maps.Data.Polygon([
      //     outerCoords,
      //     innerCoords1,
      //     innerCoords2,
      //   ]),
      // });
      setMap(map)
    }
  }, [map])

  useEffect(() => {

  }, [lat, lng]);

  const paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 }
  ]

  const options = {
    fillColor: "orange",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }


  return (
    <>
      <div style={{ height: '50vh', width: '100%' }}>
        {isLoaded ? (
          <GoogleMap
            options={{
              clickableIcons: false,
              streetViewControl: false,
              zoomControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              isFractionalZoomEnabled: true,
              styles: [
                {
                  featureType: 'poi.business',
                  stylers: [{ visibility: "off" }]
                },
                {
                  featureType: 'poi.sports_complex',
                  stylers: [{ visibility: "off" }]
                }
              ]
            }}
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
            center={{
              lat: lat,
              lng: lng,
            }}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            { /* Child components, such as markers, info windows, etc. */}
            <Polygon
              onLoad={onLoad}
              paths={paths}
              options={options}
            />
            <></>
          </GoogleMap>
        ) : (
          <>Not loading</>
        )}
      </div>
    </>
  );
}

export {
  SimpleMap
}