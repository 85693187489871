import { Alignment, Button, Card, CardList, Collapse, Elevation, InputGroup, Navbar, NonIdealState, Section, Spinner } from '@blueprintjs/core';

const Result = ({ resultSelected, filters, result }: any) => {
  const renderObject = (obj: any) => {
    return (
      <div className='flex flex-col justify-between w-full'>
        {
          Object.entries(obj)
            .filter(([key, value]) => [...filters].includes(key))
            .map(([key, value], index) => (
              <div className={`flex flex-row w-1/1 ${index % 2 == 0 && 'bg-gray-50'} p-2`}>
                <div className='flex flex-row items-center w-full justify-between' key={index}>
                  <span className='font-bold capitalize'>{key.replaceAll('_', ' ')}</span>
                  <span>{renderValue(value)}</span>
                </div>
              </div>
            ))
        }
      </div>
    );
  };

  const renderArray = (arr: any) => {
    return (
      <ul>
        {arr.map((item: any, index: any) => (
          <li key={index}>{renderValue(item)}</li>
        ))}
      </ul>
    );
  };

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return renderArray(value);
    } else if (typeof value === 'object' && value !== null) {
      return renderObject(value);
    } else if (typeof value === 'number' && value.toString().includes('.')) {
      return (
        <>
          {
            (value) ? (
              <code className='font-bold bg-gray-200 rounded px-2 py-1 text-black'>{value.toLocaleString(undefined, { maximumFractionDigits: 3 })}</code>
            ) : (
              <></>
            )
          }
        </>
      )
    } else if (typeof value === 'number' && value > 100_000 && !value.toString().includes('.')) {
      return (
        <>
          {
            (value) ? (
              <code className='font-bold bg-gray-200 rounded px-2 py-1 text-black'>{value.toLocaleString(undefined)}</code>
            ) : (
              <></>
            )
          }
        </>
      )
    } else {
      return (
        <>
          {
            (value) ? (
              <code className='font-bold bg-gray-200 rounded px-2 py-1 text-black'>{value.toString()}</code>
            ) : (
              <></>
            )
          }
        </>
      )
    }
  };

  return (
    <Card onClick={() => resultSelected(result)} interactive>
      <div className='flex flex-row w-full my-4'>
        {renderObject(result)}
      </div>
    </Card>
  )
}

const ResultsContainer = ({ resultSelected, filters, results }: any) => {
  return (
    <>
      {
        (results && results.length != 0) ? (
          <div className='flex flex-col gap-2 w-full'>
            <CardList>
              {(results.slice(0, 10)).map((r: any) => <Result resultSelected={resultSelected} filters={filters} result={r} />)}
            </CardList>
          </div>
        ) : (
          <NonIdealState
            icon='database'
            title='No search results'
            description={<p>Your search didn't match any files.
              Try searching for something else, or create a new file.</p>} />
        )
      }
    </>
  )
}


export {
  Result,
  ResultsContainer
}