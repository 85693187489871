class Backend {
  static getHost() {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:8080/'
    }
    return 'https://goldfish-app-kc9hs.ondigitalocean.app/'
  }

  static geocode(query: string): any {
    return fetch(`${Backend.getHost()}/geocode?query=${query}`)
  }
  
  static prompt(value: string, sourceType: any): any {
    return fetch(`${Backend.getHost()}/prompt?value=${value}&source=${sourceType}`)
  }
}

export {
  Backend
}