import { Alignment, Button, Card, CardList, Collapse, Divider, Elevation, HTMLSelect, Icon, InputGroup, Navbar, NonIdealState, Section, Spinner, TagInput } from '@blueprintjs/core';
import { Backend } from '../backend';
import { useEffect, useState } from 'react';
import { ResultsContainer } from './result';
import { SimpleMap } from './map';
import { SignedIn } from '@clerk/clerk-react';
import { SegmentedControl } from '@blueprintjs/core';

const VotingPanel = () => {
  const [voted, setVoted] = useState(false)
  const [loading, setLoading] = useState(false)

  if (loading) {
    return (
      <div className='flex flex-row py-2 gap-2 items-center'>
        <p className='text-xs text-gray-700 my-1'>
          Sending feedback
        </p>
        <Spinner size={20} />
      </div>
    )
  }

  if (voted) {
    return (
      <div className='flex flex-col py-2 items-center'>
        <p className='text-xs text-gray-700 my-1'>
          Feedback Sent.
        </p>
      </div>
    )
  }

  const onVote = (helpful: boolean) => {
    setLoading(true)
    setTimeout(() => {
      setVoted(true)
      setLoading(false)
    }, 550)
  }

  return (
    <div className='flex flex-col py-2 pb-3'>
      <p className='text-xs text-gray-700 my-1'>
        Helpful?
      </p>
      <div className='flex flex-row gap-2'>
        <Button onClick={() => onVote(true)} outlined intent='success' icon='thumbs-up' />
        <Button onClick={() => onVote(false)} outlined intent='danger' icon='thumbs-down' />
      </div>
    </div>
  )
}

const SearchResultsContainer = ({ resultSelected, results, loading, error, hasCompletedSearch }: any) => {
  /**
   * 
   * Object.entries(obj)
            .filter(([key, value]) => !['id', 'insertedat'].includes(key))
            .map(([key, value], index) => (
      */

  const [filters, setFilters] = useState<any>([])

  useEffect(() => {
    for (const r of results) {
      // get the keys of the returned result
      const x = Array.from(new Set(Object.entries(r)
        .filter(([field, value]) => !field.startsWith('m_'))
        .map(([field, value]) => field)))

      const ef = [...x]
      const newFilters = Array.from(new Set([...ef]))

      setFilters([
        ...newFilters
      ])
    }
  }, [results])

  const handleRemove = (e: any) => {
    console.log(e)
    const s = new Set([...filters])
    s.delete(e)
    setFilters([...Array.from(s)])
  }

  if (error) {
    return (
      <div className='flex flex-col w-full'>
        <Section title='Search Results'>
          <Card>
            <NonIdealState
              icon='error'
              title='Something went wrong'
              description={<p>Please try again. If the problem continues please contact us for more help.</p>} />
          </Card>
        </Section>
      </div>
    )
  }

  return (
    <div className='flex flex-col w-full'>
      <Section title='Search Results' rightElement={<VotingPanel />}>
        {
          loading ? (
            <Card>
              <div className='flex flex-row justify-around my-8'>
                <Spinner />
              </div>
            </Card>
          ) : (
            (!hasCompletedSearch && (results || []).length == 0) ? (
              <Card>
                <NonIdealState
                  icon='database'
                  title='Nothing to see here'
                  description={<p>Try searching for something in the serch panel. Relevant results will be displayed here.</p>} />
              </Card>
            ) : (
              <Card>
                <TagInput onRemove={handleRemove} large values={filters} />

                <Divider />

                <ResultsContainer resultSelected={resultSelected} filters={filters} results={results || []} />
              </Card>
            )
          )
        }
      </Section>
    </div>
  )
}

const MapContainer = ({ selectedProperty }: any) => {
  // 1. if property contains addr information toggle the map
  const [coords, setCoords] = useState<any>({
    lat: 51.55208,
    lng: -0.18673,
    zoom: 15,
  })

  useEffect(() => {
    if (selectedProperty && selectedProperty.post_code) {
      Promise.resolve(Backend.geocode(selectedProperty.post_code))
        .then((response: any) => response.json())
        .then((obj: any) => {
          if (obj.features) {
            const ft = obj.features[0]
            const coord = ft.geometry.coordinates
            setCoords({
              ...coords,
              lat: coord[1],
              lng: coord[0],
            })
          }
        })
    }
  }, [selectedProperty])

  return (
    <div className='w-1/2'>
      <Card>
        <SimpleMap zoom={coords.zoom} lat={coords.lat} lng={coords.lng} />

        <div className='flex flex-col my-4'>
          <p>
            Lat: {coords.lat} Long: {coords.lng}
          </p>
        </div>
      </Card>
    </div>
  )
}

const Dash = () => {
  const [placeholder, setPlaceholder] = useState('Find me all properties that sold within the last week under 250K')
  const [promptValue, setPromptValue] = useState<any>(undefined)
  const [results, setResults] = useState([])
  const [hasCompletedSearch, setHasCompletedSearch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [value, setValue] = useState('PLOT')
  const [selectedProperty, setSelectedProperty] = useState()

  const resultSelected = (result: any) => {
    setSelectedProperty(result)
  }

  const handleSearchPrompt = () => {
    if (!promptValue) {
      return
    }

    setHasCompletedSearch(true)
    setLoading(true)
    setError(null)

    Promise.resolve(Backend.prompt(promptValue, value))
      .then((resp) => {
        if (!resp.ok) {
          throw new Error('unexpected problemo')
        }

        console.log('resp', resp)
        try {
          return resp.json()
        } catch (e) {
          throw e;
        }
      })
      .then((obj: any) => {
        setResults(obj)
        setLoading(false)
      })
      .catch(err => {
        console.error('Fetch error:', err);
        setLoading(false);
        setError(err)
      });
  }

  return (
    <div className='flex flex-row w-full gap-4 m-4'>
      <div className={`flex flex-col gap-4 w-1/2`}>
        <div className='w-full flex flex-col gap-4'>
          <Section title='Search'>
            <Card>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col w-full my-2'>
                  <div className='flex flex-row w-full gap-1'>
                    <InputGroup
                      className='w-full'
                      value={promptValue}
                      onChange={(e: any) => setPromptValue(e.target.value)}
                      placeholder={placeholder}
                      large
                      rightElement={
                        <button disabled={!promptValue} onClick={handleSearchPrompt} className={`bp5-button bp5-minimal bp5-intent-primary bp5-icon-arrow-right`} />
                      }>
                    </InputGroup>

                    <HTMLSelect
                      className='w-1/3'
                      large
                      options={[
                        {
                          label: "Plots",
                          value: "PLOT",
                        },
                        {
                          label: "Price Paid",
                          value: "PRICE_PAID",
                        },
                      ]}
                      value={value}
                      onChange={(e: any) => setValue(e.target.value)}
                    />
                  </div>
                  <div className='text-xs flex flex-col text-left w-full mt-2'>
                    <div>
                      <p className='italic text-gray-600 mt-1'>
                        <span className='font-bold'>ResGrok is currently in development</span>. Information may be partial or out of date.
                        It is possible that the query might not match the results perfectly. Consider checking important information
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Section>

          <Section
            title='Past queries'
            collapseProps={{
              defaultIsOpen: false
            }}
            collapsible>
            <CardList compact>
              <Card
                interactive={true}>
                <div className='flex flex-row w-full items-center justify-between'>
                  <p>
                    Find me all the properties that sold in the last N days
                  </p>
                  {/* <Button minimal>Load</Button> */}
                </div>
              </Card>
            </CardList>
          </Section>
        </div>

        <SearchResultsContainer
          error={error}
          loading={loading}
          hasCompletedSearch={hasCompletedSearch}
          resultSelected={resultSelected}
          results={results} />
      </div>

      {
        selectedProperty &&
        <MapContainer selectedProperty={selectedProperty} />
      }
    </div>
  )
}

export {
  Dash
}